import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PPPAccess = () => (
  <Layout>
    <SEO title="PPP Access" />
    <div className="container primary-bg">
      <div className="row">
        <div className="col">
          <h1>PPP Access</h1>
          <Link className="full-cta" to="/funding">Registration Form</Link>
          <Link className="full-cta" to="/additional-funding">Additional Funding Sources</Link>
          <Link className="full-cta" to="/sba-approved-lenders">SBA Approved Lenders</Link>
        </div>
      </div>
    </div>
  
    
  </Layout>
)

export default PPPAccess
